"use client"

import React, { useCallback, useEffect, useState } from "react"
import { Content } from "@prismicio/client"
import useEmblaCarousel from "embla-carousel-react"
import { ChevronLeft, ChevronRight } from "lucide-react"

import ProductCardHydated from "../products/ProductCardHydrated"
import { Button } from "../ui/button"

interface PropsIf {
  products: Content.ProductDocument<string>[]
}
export default function ProductSlider({ products }: PropsIf) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    dragFree: true,
    containScroll: "trimSnaps",
  })

  const [touched, setTouched] = useState(false)

  const handleTouch = () => {
    setTouched(true)
  }

  // Auto-scroll function
  const autoScroll = useCallback(() => {
    if (emblaApi && !touched) {
      emblaApi.scrollNext()
    }
  }, [emblaApi, touched])

  useEffect(() => {
    const interval = setInterval(autoScroll, 2000) // Set up the interval

    return () => clearInterval(interval) // Clear the interval when the component unmounts
  }, [autoScroll])

  return (
    <>
      <div className="hidden md:flex gap-2">
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide left"
          onClick={() => emblaApi?.scrollPrev()}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide right"
          onClick={() => emblaApi?.scrollNext()}
        >
          <ChevronRight />
        </Button>
      </div>
      <div
        className="my-2 border border-carbon-500 rounded-sm overflow-hidden h-full"
        ref={emblaRef}
      >
        <div
          className="flex touch-pan-y ml-[calc(1rem_*_-1)] h-full cursor-grab"
          onMouseDown={handleTouch}
          onDrag={handleTouch}
          onTouchStart={handleTouch}
        >
          {products.map((product, i) => {
            return (
              <div
                className="flex-[0_0_40%] sm:flex-[0_0_35%] md:flex-[0_0_25%] lg:flex-[0_0_18%] min-w-0 relative pl-2"
                key={"reviewGroup" + i}
              >
                <div className="grid grid-cols-1 justify-between gap-[1px] h-full">
                  <ProductCardHydated
                    singleLine={true}
                    product={product}
                    enable3d={true}
                    disableLink={true}
                    key={product.id + "-" + i}
                    category={product.data.category as any}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="md:hidden flex gap-2">
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide left"
          onClick={() => emblaApi?.scrollPrev()}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide right"
          onClick={() => emblaApi?.scrollNext()}
        >
          <ChevronRight />
        </Button>
      </div>
    </>
  )
}
function setActiveSlide(arg0: number) {
  throw new Error("Function not implemented.")
}
