"use client"

import React, { useEffect } from "react"
import { DealerImages } from "@prisma/client"
import { type EmblaOptionsType } from "embla-carousel"
import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"

interface PropsIf {
  images: DealerImages[]
  options?: EmblaOptionsType
  activeSlide?: number
  setActiveSlide?: (i: number) => void
}
export default function DealerHeroSlider(props: PropsIf) {
  const { images, options, activeSlide, setActiveSlide } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({
      delay: 5000,
    }),
  ])

  useEffect(() => {
    if (activeSlide !== undefined) emblaApi?.scrollTo(activeSlide)
  }, [activeSlide])

  const onSelect = () => {
    if (!emblaApi || !setActiveSlide) return
    setActiveSlide(emblaApi.selectedScrollSnap())
  }

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on("select", onSelect)
    emblaApi.on("reInit", onSelect)
  }, [emblaApi])

  return (
    <div className="h-full max-h-[700px]">
      <div className="overflow-hidden h-full" ref={emblaRef}>
        <div className="flex touch-pan-y ml-[calc(1rem_*_-1)] h-full">
          {images.map((image, i) => (
            <div className="flex-[0_0_100%] min-w-0 relative pl-2" key={i}>
              <img
                className="h-full object-cover max-h-[700px] w-full"
                src={`https://cdn.onedaypro.com/-/preview/-/resize/1200x/https://onedaypro.com/dealer_images/${image.filename}`}
                loading={"eager"}
                fetchPriority="high"
                alt={image.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
