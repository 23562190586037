"use client"

import { useEffect, useState } from "react"
import { Content } from "@prismicio/client"
import { PrismicNextLink } from "@prismicio/next"
import { ArrowRight } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { PrismicTextBlockCopy } from "@/components/PrismicTextBlock"

import MainHeroSlider from "./MainHeroSlider"

export default function MainHero({
  slice,
  buttons,
  icons,
}: {
  slice: Content.HeroSliceTabbedHero
  buttons: React.ReactNode
  icons: JSX.Element[]
}): JSX.Element {
  const [activeItem, setActiveItem] = useState(0)
  const [label, setLabel] = useState(slice.items[0].label ?? "")
  const [progressBar, setProgressBar] = useState(0)
  useEffect(() => {
    const final = slice.items[activeItem].label
    if (final) {
      if (label !== "" && !final.includes(label)) return setLabel("Pro")
      if (label === slice.items[activeItem].label) return
      const timer = setTimeout(
        () => {
          setLabel((current) => {
            const currentPos = current.length
            if (final[currentPos] !== undefined) {
              current += final.charAt(currentPos)
            }

            return current
          })
        },
        275 / (final.length - 3)
      )
      return () => clearTimeout(timer)
    }
  }, [activeItem, label])

  useEffect(() => {
    const delay = 10000
    setProgressBar(0)
    const progress = setInterval(
      () => {
        setProgressBar((current) => {
          current += 10
          return current
        })
      },
      (delay - 1000) / 10
    )
    const delayDebounceFn = setTimeout(() => {
      setActiveItem((current) => {
        return (current + 1) % slice.items.length
      })
      setProgressBar(0)
      clearInterval(progress)
    }, delay)

    return () => {
      clearTimeout(delayDebounceFn)
      clearInterval(progress)
    }
  }, [activeItem])

  function handleChangeActive(i: number) {
    setProgressBar(0)
    setActiveItem(i)
  }

  return (
    <section className={"overflow-hidden"}>
      <div className="lg:container grid items-center gap-12 lg:grid-cols-2 lg:gap-20">
        <div className="max-lg:container flex flex-col gap-4 pb-4 pt-12 lg:py-28 slide-in-from-bottom-6 animate-in fade-in-0 duration-700">
          <h1 className="text-flame-500 text-4xl min-[360px]:text-5xl lg:text-5xl flex flex-col md:flex-row gap-2 flex-wrap">
            <span className="whitespace-nowrap">One Day</span>{" "}
            <span className="text-carbon-500">{label}</span>
            <span className="sr-only">
              One Day {slice.items[activeItem].label}
            </span>
          </h1>
          <div className="flex flex-wrap gap-2 mb-4 mt-2">
            {slice.items.map((item, i) => {
              return (
                <Button
                  variant={
                    activeItem === i ? "outline-active" : "outline-inactive"
                  }
                  key={"hero-nav-button-" + item.label}
                  onClick={() => handleChangeActive(i)}
                  className={cn("relative")}
                >
                  {icons[i]}
                  {item.label}
                  <div
                    className={cn(
                      "absolute bottom-0 left-0 w-full max-w-[325px] bg-carbon-100 h-[1px] overflow-hidden",
                      activeItem !== i && "hidden"
                    )}
                  >
                    <div
                      className="bg-carbon-500 h-[1px] transition-all"
                      style={{
                        width: progressBar + "%",
                        transitionDuration: "1000ms",
                        transitionTimingFunction: "linear",
                      }}
                    ></div>
                  </div>
                </Button>
              )
            })}
          </div>

          <PrismicTextBlockCopy
            field={slice.items[activeItem].description}
            className="max-w-[475px] min-h-[150px] md:min-h-[160px]"
          />
          <div className="flex flex-wrap gap-2 mt-2">
            {slice.items[activeItem].learn_more &&
              (slice.items[activeItem].learn_more as any).id && (
                <Button asChild>
                  <PrismicNextLink field={slice.items[activeItem].learn_more}>
                    Our{" "}
                    {slice.items[activeItem].label}
                    <ArrowRight className="size-4 ml-2" />
                  </PrismicNextLink>
                </Button>
              )}
            {buttons}
          </div>
        </div>
        <div className="lg:mr-break-out overflow-hidden h-full">
          <MainHeroSlider
            images={slice.items.map((item) => item.image)}
            options={{ loop: true }}
            activeSlide={activeItem}
            setActiveSlide={handleChangeActive}
          />
        </div>
      </div>
    </section>
  )
}
