"use client"

import { useState } from "react"
import { DealerReviews } from "@prisma/client"
import { Star } from "lucide-react"

import { cn } from "@/lib/utils"

import GoogleSVG from "../assets/GoogleSVG"
import YelpSVG from "../assets/YelpSVG"
import { Button } from "../ui/button"

export default function ReviewCard({
  review,
  className,
}: {
  review: DealerReviews
  className?: string
}) {
  const [readMore, setReadMore] = useState(false)

  function showContent() {
    if (review.content.length < 200 || readMore) return review.content
    else {
      const content = review.content.slice(0, 160).split(" ")
      content.pop()
      return content.join(" ") + "..."
    }
  }

  return (
    <div
      className={cn(
        "outline-carbon-500 outline outline-1 p-6 flex flex-col justify-between gap-3 h-full",
        className
      )}
    >
      <p className={"text-carbon-50"}>
        <span dangerouslySetInnerHTML={{ __html: showContent() }}></span>{" "}
        {review.content.length >= 200 && (
          <>
            {readMore ? (
              <Button
                className="text-carbon-200"
                variant={"link"}
                onClick={() => setReadMore(false)}
              >
                show less
              </Button>
            ) : (
              <Button
                className="text-carbon-200"
                variant={"link"}
                onClick={() => setReadMore(true)}
              >
                read more
              </Button>
            )}
          </>
        )}
      </p>
      <div className="flex flex-row gap-2 justify-between items-center flex-wrap">
        <div className="flex flex-row gap-3 items-center">
          {review.source === "google" ? <GoogleSVG /> : <YelpSVG />}
          <div>
            <p className="text-lg font-heading text-white">
              {review.name.split(" ")[0]}
            </p>
            <p className="text-carbon-200 text-xs">
              {new Date(review.date_created).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "America/New_York",
              })}
            </p>
          </div>
        </div>
        <div className="flex gap-1">
          <Star className="fill-amber-400 text-amber-400 size-5" />
          <Star className="fill-amber-400 text-amber-400 size-5" />
          <Star className="fill-amber-400 text-amber-400 size-5" />
          <Star className="fill-amber-400 text-amber-400 size-5" />
          <Star className="fill-amber-400 text-amber-400 size-5" />
        </div>
      </div>
    </div>
  )
}
