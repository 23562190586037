"use client"

import { DealerImages } from "@prisma/client"
import Autoplay from "embla-carousel-autoplay"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import DealerFigure from "@/components/images/DealerFigure"
import { TextBlockHeading } from "@/components/TextBlock"

export default function DealerGalleryImages({
  title,
  images,
}: {
  title: string
  images: DealerImages[]
}) {
  return (
    <Carousel
      opts={{
        align: "start",
        loop: true,
        containScroll: "trimSnaps",
      }}
      plugins={[
        Autoplay({
          delay: 3500,
        }),
      ]}
      className="w-full"
    >
      <div className="flex justify-between w-full gap-2 flex-wrap items-end px-1 mb-6">
        <TextBlockHeading className="text-white">{title}</TextBlockHeading>
        <div className="flex gap-2">
          <CarouselPrevious variant={"secondary-dark"} />
          <CarouselNext variant={"secondary-dark"} />
        </div>
      </div>
      <CarouselContent>
        {images.map((image, index) => (
          <CarouselItem
            key={title + "_" + index}
            className="md:basis-1/2 lg:basis-1/3"
          >
            <div className="p-1">
              <DealerFigure
                image={image}
                pictureClassName="border-carbon-600 aspect-square"
                captionClassName="[&>p]:text-carbon-100"
              />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}
