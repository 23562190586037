import { useEffect, useRef } from "react"
import { KeyTextField } from "@prismicio/client"

export default function VideoNative({
  video_url,
}: {
  video_url: KeyTextField
}) {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef && videoRef.current) {
      ;(videoRef.current as any).play()
    }
  }, [])

  if (video_url)
    return (
      <video
        ref={videoRef}
        src={video_url}
        preload="auto"
        autoPlay={true}
        controls={true}
        style={{ width: "100%", height: "100%", maxHeight: "80vh" }}
      ></video>
    )
  else return <></>
}
