"use client"

import React, { useEffect } from "react"
import { ImageField } from "@prismicio/client"
import { PrismicNextImage } from "@prismicio/next"
import { type EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"

interface PropsIf {
  images: ImageField[]
  options?: EmblaOptionsType
  activeSlide?: number
  setActiveSlide?: (i: number) => void
}
export default function MainHeroSlider(props: PropsIf) {
  const { images, options, activeSlide, setActiveSlide } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  useEffect(() => {
    if (activeSlide !== undefined) emblaApi?.scrollTo(activeSlide)
  }, [activeSlide])

  const onSelect = () => {
    if (!emblaApi || !setActiveSlide) return
    setActiveSlide(emblaApi.selectedScrollSnap())
  }

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on("select", onSelect)
    emblaApi.on("reInit", onSelect)
  }, [emblaApi])

  return (
    <div className="h-full max-h-[700px]">
      <div className="overflow-hidden h-full" ref={emblaRef}>
        <div className="flex touch-pan-y ml-[calc(1rem_*_-1)] h-full">
          {images.map((image, i) => (
            <div className="flex-[0_0_100%] min-w-0 relative pl-2" key={i}>
              <PrismicNextImage fallbackAlt={""}
                loading={"eager"}
                priority={true}
                field={image}
                className="h-full object-cover"
                sizes={"100vw"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
