"use client"

import { DealerImages } from "@prisma/client"

import { cn } from "@/lib/utils"

interface PropsIf {
  image: DealerImages
  pictureClassName?: string
  imgClassName?: string
  captionClassName?: string
  lightbox?: boolean
}

export default function DealerFigure({
  image,
  pictureClassName,
  imgClassName,
  captionClassName,
}: PropsIf) {
  const generateSrcSet = (
    filename: string,
    format = "auto",
    quality = "lighter"
  ) => {
    const sizes = [540, 750, 1000, 1500, 2200, 3000]
    return sizes
      .map(
        (size) =>
          `https://cdn.onedaypro.com/-/quality/${quality}/-/format/${format}/-/resize/${size}x/https://onedaypro.com/dealer_images/${filename} ${size}w`
      )
      .join(", ")
  }

  const src = `https://cdn.onedaypro.com/-/preview/-/resize/1200x/https://onedaypro.com/dealer_images/${image.filename}`
  const srcSet = generateSrcSet(image.filename)
  return (
    <figure>
      <picture
        className={cn(
          "block rounded-sm overflow-hidden border border-carbon-200 relative",

          pictureClassName
        )}
        style={{ height: "100%", width: "100%" }}
      >
        <img
          alt={image.description ?? image.name ?? "Dealer Image"}
          className={cn(
            "object-cover h-full w-full transition duration-300 ease-in-out opacity-100",
            imgClassName
          )}
          loading="lazy"
          height="100%"
          width="100%"
          decoding="async"
          sizes="100vw"
          srcSet={srcSet}
          src={src}
          style={{ width: "100%", height: "100%" }}
        />
      </picture>
      <figcaption
        className={cn(
          "mt-3 max-w-3xl text-sm italic text-carbon-500",
          captionClassName
        )}
      >
        <p>{image.description ?? image.name}</p>
      </figcaption>
    </figure>
  )
}
