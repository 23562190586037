"use client"

import { Content } from "@prismicio/client"
import { PrismicNextImage } from "@prismicio/next"
import { Play, PlayCircle } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import PrismicTextBlock from "@/components/PrismicTextBlock"
import VideoPopup from "@/components/video-popup/VideoPopup"

import MainHeroSlider from "../MainHero/MainHeroSlider"

export default function DefaultHero({
  slice,
  buttons,
}: {
  slice: Content.HeroSliceDefault
  buttons: React.ReactNode
}): JSX.Element {
  return (
    <section className={"overflow-hidden"}>
      <div
        className={cn(
          "lg:container grid items-center gap-12 lg:grid-cols-2 lg:gap-20",
          slice.primary.mobile_order === "image_text"
            ? "max-lg:flex max-lg:flex-col-reverse max-lg:mb-14 max-lg:gap-8"
            : ""
        )}
      >
        <div className="max-lg:container flex flex-col gap-4 pb-4 pt-12 lg:py-28 slide-in-from-bottom-6 animate-in fade-in-0 duration-700">
          <PrismicTextBlock
            {...slice.primary}
            className="max-w-[475px] min-h-[150px] md:min-h-[125px]"
          />
          <div className="flex flex-wrap gap-2 mt-5">
            {buttons}
            <VideoPopup {...slice.primary}>
              <Button variant={"default"}>
                Play Video <PlayCircle className="size-4 ml-2" />
              </Button>
            </VideoPopup>
          </div>
        </div>
        <div className="lg:mr-break-out overflow-hidden h-full relative">
          <VideoPopup {...slice.primary}>
            <div className="transition-all cursor-pointer absolute top-0 left-0 size-full bg-carbon-50/0 hover:bg-carbon-50/40 flex items-center justify-center">
              <Button variant={"default"} size={"icon"} className="size-12">
                <Play className="size-6" />
              </Button>
            </div>
          </VideoPopup>

          {slice.items.length > 0 && (
            <>
              {slice.items.length === 1 ? (
                <PrismicNextImage fallbackAlt={""}
                  priority={true}
                  loading={"eager"}
                  field={slice.items[0].image}
                  className="h-full object-cover  max-h-[700px]"
                  sizes={"100vw"}
                />
              ) : (
                <MainHeroSlider
                  images={slice.items.map((item) => item.image)}
                  options={{ loop: true }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}
