import Link from "next/link"
import { ProductCategoryDocument, ProductDocument } from "@/prismicio-types"
import { PrismicNextImage } from "@prismicio/next"

import { cn } from "@/lib/utils"

export default function ProductCardHydated({
  product,
  category,
  enable3d,
  disableLink,
  singleLine,
}: {
  product: ProductDocument
  category: ProductCategoryDocument
  enable3d?: boolean
  disableLink?: boolean
  singleLine?: boolean
}) {
  const card = (
    <div
      className={cn(
        "p-4 md:p-6 lg:p-8 group duration-500",
        "flex flex-col gap-2 justify-between h-full"
      )}
    >
      <div>
        <div className="flex items-center justify-between gap-2 flex-wrap">
          <h3
            className={cn(
              "text-lg lg:text-xl text-white",
              singleLine &&
                "truncate max-w-xs"
            )}
          >
            {product.data.name}
          </h3>
        </div>
        <h4 className="text-sm text-white mb-2">
          {category.data.display_name}
        </h4>
      </div>
      <PrismicNextImage fallbackAlt={""}
        field={product.data.image}
        className={cn(
          enable3d && !product.data.disable_3d
            ? "box-shadow-3d bg-carbon-50"
            : !product.data.disable_3d &&
                "group-hover:box-shadow-3d transition-all duration-150 group-hover:translate-x-2 group-hover:translate-y-2",
          " "
        )}
      />
    </div>
  )

  if (disableLink) return card
  return <Link href={"/products/" + product.uid}>{card}</Link>
}
