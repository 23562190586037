"use client"

import { KeyTextField } from "@prismicio/client"

import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog"
import VideoNative from "./VideoNative"

export default function VideoPopup({
  youtube_id,
  vimeo_id,
  video_url,
  children,
}: {
  youtube_id: KeyTextField
  vimeo_id: KeyTextField
  video_url: KeyTextField
  children: React.ReactNode
}) {
  return (
    <>
      {youtube_id && (
        <Dialog>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent className="!container h-fit max-w-[calc(100vw-40px)] md:max-w-[calc(100vw-100px)] !p-0 overflow-hidden rounded aspect-video border-0">
            <iframe
              className="size-full"
              src={`https://youtube.com/embed/${youtube_id}?autoplay=1&controls=1&rel=0&showinfo=0&autohide=1&mute=1`}
              allowFullScreen
              allow="autoplay"
            />
          </DialogContent>
        </Dialog>
      )}
      {vimeo_id && (
        <Dialog>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent className="!container h-fit max-w-[calc(100vw-40px)] md:max-w-[calc(100vw-100px)] !p-0 overflow-hidden rounded aspect-video border-0">
            <div style={{ padding: "56.25% 0 0 0" }} className="relative">
              <iframe
                src={`https://player.vimeo.com/video/${vimeo_id}?h=d063a6fe74&autoplay=1&color=F04E23&title=0&byline=0&portrait=0`}
                className="absolute top-0 left-0 size-full"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js" async></script>
          </DialogContent>
        </Dialog>
      )}
      {video_url && (
        <Dialog>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent className="!container max-w-[calc(100vw-40px)] md:!max-w-[1280px] !p-0 overflow-hidden rounded border-0 bg-carbon-900">
            <VideoNative video_url={video_url} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
