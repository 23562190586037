export default function GoogleSVG({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={28}
      height={29}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0004 14.811C28.0004 13.6599 27.9051 12.8199 27.6988 11.9488H14.2861V17.1443H22.1591C22.0004 18.4355 21.1432 20.3799 19.2384 21.6865L19.2117 21.8605L23.4526 25.0801L23.7464 25.1088C26.4448 22.6666 28.0004 19.0732 28.0004 14.811Z"
        fill="#4285F4"
      />
      <path
        d="M14.2853 28.4999C18.1424 28.4999 21.3812 27.2555 23.7464 25.1088L19.2384 21.6865C18.0321 22.511 16.4122 23.0864 14.2853 23.0864C10.5076 23.0864 7.30127 20.6442 6.15831 17.2687L5.99078 17.2826L1.5811 20.6271L1.52343 20.7842C3.87261 25.3575 8.69802 28.4999 14.2853 28.4999Z"
        fill="#34A853"
      />
      <path
        d="M6.15831 17.2687C5.85673 16.3976 5.68254 15.4646 5.68254 14.5002C5.68254 13.5357 5.85708 12.6024 6.14279 11.7313L6.1348 11.5457L1.66985 8.14758L1.52377 8.21568C0.55556 10.1135 0 12.2446 0 14.5002C0 16.7557 0.55522 18.8864 1.52343 20.7842L6.15831 17.2687Z"
        fill="#FBBC05"
      />
      <path
        d="M14.2854 5.91342C16.9679 5.91342 18.7774 7.04897 19.8092 7.99792L23.8409 4.14012C21.3648 1.88457 18.1425 0.500122 14.2854 0.500122C8.69802 0.500122 3.87296 3.64239 1.52377 8.21568L6.14279 11.7313C7.30163 8.35577 10.5076 5.91342 14.2854 5.91342Z"
        fill="#EB4335"
      />
    </svg>
  )
}
