"use client"

import { useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"
import useOnScreen from "@/hooks/use-on-screen"

const DealerMap = dynamic(() => import("./DealerMap"))

interface PropsIf {
  currentDealer: GetSingleDealerReturnType
}

export default function DealerMapLazy({ currentDealer }: PropsIf) {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  return (
    <div ref={ref}>
      {isVisible && <DealerMap currentDealer={currentDealer} />}
    </div>
  )
}
