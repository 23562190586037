"use client"

import { useState } from "react"
import { Content } from "@prismicio/client"
import { PrismicNextImage } from "@prismicio/next"
import { SliceComponentProps } from "@prismicio/react"
import Autoplay from "embla-carousel-autoplay"

import { cn } from "@/lib/utils"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
// import {
//   Dialog,
//   DialogContent,
// } from "@/components/ui/dialog"
import PrismicTextBlock, {
  PrismicTextBlockCopy,
} from "@/components/PrismicTextBlock"

export type GalleryProps = SliceComponentProps<Content.GallerySlice>

export default function PrismicGallery({ slice }: GalleryProps) {
  const { primary, items } = slice
  const [fullscreen, setFullscreen] = useState(false)

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen)
  }

  return (
    <>
      <Carousel
        opts={{
          align: "start",
          loop: true,
          containScroll: "trimSnaps",
        }}
        plugins={[
          Autoplay({
            delay: 3000,
          }),
        ]}
        className="w-full"
      >
        <div className="flex justify-between w-full gap-2 flex-wrap items-end px-1 mb-6">
          <PrismicTextBlock
            {...primary}
            className={cn("md:min-w-[440px] md:max-w-[550px]")}
            enable_yt={true}
            variant={"dark"}
          ></PrismicTextBlock>
          <div className="flex gap-2">
            <CarouselPrevious variant={"secondary-dark"} />
            <CarouselNext variant={"secondary-dark"} />
          </div>
        </div>
        <CarouselContent>
          {items.map(({ image, caption }) => (
            <CarouselItem
              key={image.url + "-gallery"}
              className="basis-[90%] md:basis-1/2 lg:basis-[40%]"
              onClick={toggleFullscreen}
            >
              <div className="p-1">
                <figure className="border-carbon-600 aspect-[16/11]">
                  <PrismicNextImage
                    fallbackAlt={""}
                    field={image}
                    className="w-full h-full object-cover"
                  />
                  <figcaption>
                    <PrismicTextBlockCopy
                      variant={"dark"}
                      field={caption}
                      className={cn("italic text-carbon-300 pt-[2px]")}
                    />
                  </figcaption>
                </figure>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      {/* <Dialog open={fullscreen} onOpenChange={toggleFullscreen}>
        <DialogContent className="bg-carbon-900 max-w-[60vw] max-h-[95vh] &[data-close=true]:top-7 data-[close=true]:right-7">
          <Carousel
            opts={{
              align: "start",
              loop: true,
              containScroll: "trimSnaps",
            }}
            plugins={[
              Autoplay({
                delay: 3000,
              }),
            ]}
            className="w-full"
          >
            <div className="flex justify-between w-full gap-1 flex-wrap items-end px-1 mb-2">
              <div className="flex gap-2">
                <CarouselPrevious variant={"secondary-dark"} />
                <CarouselNext variant={"secondary-dark"} />
              </div>
            </div>
            <CarouselContent className="h-full">
              {items.map(({ image, caption }) => (
                <CarouselItem
                  key={image.url + "-gallery"}
                  className="basis-[100%] h-full"
                >
                  <div className="p-1 h-full">
                    <figure className="border-carbon-600">
                      <PrismicNextImage
                        fallbackAlt={""}
                        field={image}
                        className="w-full h-full object-cover"
                      />
                      <figcaption>
                        <PrismicTextBlockCopy
                          variant={"dark"}
                          field={caption}
                          className={cn("italic text-carbon-300 pt-[2px]")}
                        />
                      </figcaption>
                    </figure>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </DialogContent>
      </Dialog> */}
    </>
  )
}
