import React, { HTMLProps } from "react"
import {
  asText,
  KeyTextField,
  LinkField,
  RichTextField,
} from "@prismicio/client"
import { PrismicNextLink } from "@prismicio/next"
import { PrismicRichText } from "@prismicio/react"
import { cva, VariantProps } from "class-variance-authority"
import { ArrowRight, BadgeCheck, PlayCircle } from "lucide-react"

import { cn } from "@/lib/utils"

import { Badge } from "./ui/badge"
import { Button } from "./ui/button"
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog"

const headingVariants = cva("font-heading", {
  variants: {
    variant: {
      light: "text-carbon-500",
      dark: "text-white",
    },
  },
  defaultVariants: {
    variant: "light",
  },
})

const copyVariants = cva("text-sm", {
  variants: {
    variant: {
      light: "text-carbon-400",
      dark: "text-carbon-100",
    },
  },
  defaultVariants: {
    variant: "light",
  },
})

interface BlockIf extends VariantProps<typeof headingVariants> {
  eyebrow?: KeyTextField
  heading?: RichTextField
  copy?: RichTextField
  link?: LinkField
  link_name?: KeyTextField
  link_type?: "link" | "consultation" | "become_a_dealer"
  youtube_id?: KeyTextField
  enable_yt?: boolean
  children?: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
  server_buttons?: React.ReactNode[]
}

export default function PrismicTextBlock({
  eyebrow,
  heading,
  copy,
  children,
  link,
  link_name,
  link_type,
  youtube_id,
  enable_yt,
  variant,
  className,
  server_buttons = [],
}: BlockIf) {
  const buttons = server_buttons

  if (link_name && link)
    buttons.push(
      <Button asChild variant={"default"} className="w-fit">
        <PrismicNextLink field={link}>
          {link_name}
          <ArrowRight className="size-4 ml-2" />
        </PrismicNextLink>
      </Button>
    )

  if (youtube_id && enable_yt)
    buttons.push(
      <Dialog>
        <DialogTrigger asChild>
          <Button variant={"default"}>
            Play Video <PlayCircle className="size-4 ml-2" />
          </Button>
        </DialogTrigger>
        <DialogContent className="!container h-fit max-w-[calc(100vw-40px)] md:max-w-[calc(100vw-100px)] !p-0 overflow-hidden rounded aspect-video border-0">
          <iframe
            className="size-full"
            src={`https://youtube.com/embed/${youtube_id}?autoplay=1&controls=1&rel=0&showinfo=0&autohide=1&mute=1`}
            allowFullScreen
            allow="autoplay"
          />
        </DialogContent>
      </Dialog>
    )
  return (
    <>
      {eyebrow || asText(heading) || asText(copy) || children ? (
        <div className={cn("flex flex-col gap-2", className)}>
          <PrismicTextBlockEyebrow field={eyebrow} />
          <PrismicTextBlockHeading
            variant={variant}
            field={heading}
            className="mb-2"
          />
          <PrismicTextBlockCopy variant={variant} field={copy} />
          {buttons.length > 0 && (
            <div className="flex flex-wrap gap-3 mt-5">
              {buttons.map((button, i) => {
                return (
                  <React.Fragment key={heading + "-" + i}>
                    {button}
                  </React.Fragment>
                )
              })}
            </div>
          )}
          {children}
        </div>
      ) : null}
    </>
  )
}

interface HeadingIf extends VariantProps<typeof headingVariants> {
  field?: RichTextField
  className?: HTMLProps<HTMLElement>["className"]
}

export function PrismicTextBlockHeading({
  field,
  className,
  variant,
}: HeadingIf) {
  return (
    <PrismicRichText
      field={field}
      components={{
        heading1: ({ children }) => (
          <h1
            className={cn(
              headingVariants({ variant }),
              "text-4xl md:text-5xl",
              className
            )}
          >
            {children}
          </h1>
        ),
        heading2: ({ children }) => (
          <h2
            className={cn(headingVariants({ variant }), "text-4xl", className)}
          >
            {children}
          </h2>
        ),
        heading3: ({ children }) => (
          <h3
            className={cn(headingVariants({ variant }), "text-3xl", className)}
          >
            {children}
          </h3>
        ),
      }}
    />
  )
}

interface CopyIf extends VariantProps<typeof copyVariants> {
  field?: RichTextField
  className?: HTMLProps<HTMLElement>["className"]
}

export function PrismicTextBlockCopy({ field, className, variant }: CopyIf) {
  return (
    <PrismicRichText
      field={field}
      components={{
        heading1: ({ children }) => (
          <h1
            className={cn(
              headingVariants({ variant }),
              "text-4xl md:text-5xl",
              className
            )}
          >
            {children}
          </h1>
        ),
        heading2: ({ children }) => (
          <h2
            className={cn(headingVariants({ variant }), "text-4xl", className)}
          >
            {children}
          </h2>
        ),
        heading3: ({ children }) => (
          <h3
            className={cn(headingVariants({ variant }), "text-3xl", className)}
          >
            {children}
          </h3>
        ),
        heading4: ({ children }) => (
          <h4
            className={cn(headingVariants({ variant }), "text-base font-copy", className)}
          >
            {children}
          </h4>
        ),
        paragraph: ({ children }) => (
          <p className={cn(copyVariants({ variant }), className)}>{children}</p>
        ),
        list: ({ children }) => (
          <ul
            className={cn(
              "mt-2 flex flex-col gap-2",
              copyVariants({ variant }),
              className
            )}
          >
            {children}
          </ul>
        ),
        oList: ({ children }) => (
          <ol
            className={cn(
              "ml-4 mt-2 flex flex-col gap-2 list-decimal",
              copyVariants({ variant }),
              className
            )}
          >
            {children}
          </ol>
        ),
        listItem: ({ children }) => (
          <li
            className={cn(
              "flex gap-2 items-start",
              copyVariants({ variant }),
              className
            )}
          >
            <div className="w-4 min-w-4 max-size-4 min-h-4 max-h-4 mt-1">
              <BadgeCheck className="text-flame-400 size-4" />
            </div>
            <span>{children}</span>
          </li>
        ),
        oListItem: ({ children }) => (
          <li className={cn("pl-2", copyVariants({ variant }), className)}>
            {children}
          </li>
        ),
      }}
    />
  )
}

export function PrismicTextBlockEyebrow({
  field,
  className,
}: {
  field?: KeyTextField
  className?: HTMLProps<HTMLElement>["className"]
}) {
  return (
    <>
      {field && (
        <Badge
          variant={"flame-outline"}
          className={cn("mb-3 w-fit", className)}
        >
          {field}
        </Badge>
      )}
    </>
  )
}
