export default function YelpSVG({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={30}
      height={31}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 0.5H4.5C2.01472 0.5 0 2.51472 0 5V26C0 28.4853 2.01472 30.5 4.5 30.5H25.5C27.9853 30.5 30 28.4853 30 26V5C30 2.51472 27.9853 0.5 25.5 0.5Z"
        fill="#D32323"
      />
      <path
        d="M8.90625 18.7227C8.90625 18.7227 7.96875 18.8984 7.85156 17.9609C7.73438 17.0234 7.85156 15.6172 8.20312 14.7383C8.55469 13.8594 9.375 14.2109 9.375 14.2109L13.1836 16.0859C13.1836 16.0859 14.2969 16.8477 13.0078 17.668M15.1172 19.3086C15.1172 19.3086 14.7656 18.1367 13.7109 18.8398L10.8398 22.0039C10.8398 22.0039 10.2539 22.7656 11.0156 23.293C11.7773 23.8203 13.0664 24.4062 14.1797 24.4648C15.293 24.5234 15.0586 23.6445 15.0586 23.6445M17.4609 17.7852C17.4609 17.7852 16.2891 17.7266 16.4648 18.8984L18.8086 22.6484C18.8086 22.6484 19.3945 23.3516 19.9805 22.9414C20.5664 22.5312 21.6211 21.3008 22.0898 20.3047C22.5586 19.3086 21.5625 19.1328 21.5625 19.1328M16.582 15.2656C16.582 15.2656 16.2305 16.3789 17.4609 16.4961L21.6797 15.3242C21.6797 15.3242 22.5586 14.9141 22.207 14.1523C21.8555 13.3906 21.1523 12.2188 20.2734 11.5156C19.3945 10.8125 18.9844 11.6914 18.9844 11.6914M13.8281 14.5625C13.8281 14.5625 14.8828 15.3242 15.293 14.0938V6.06641C15.293 6.06641 15.293 5.1875 14.3555 5.1875C13.418 5.1875 11.0742 5.77344 10.1367 6.30078C9.19922 6.82812 9.78516 7.53125 9.78516 7.53125"
        fill="white"
      />
    </svg>
  )
}
