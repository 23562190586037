"use client"

import React from "react"
import { DealerReviews } from "@prisma/client"
import useEmblaCarousel from "embla-carousel-react"
import { ChevronLeft, ChevronRight } from "lucide-react"

import { cn } from "@/lib/utils"

import ReviewCard from "../cards/ReviewCard"
import { Button } from "../ui/button"

interface PropsIf {
  reviews: DealerReviews[]
}
export default function ReviewsSlider({ reviews }: PropsIf) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const groups: DealerReviews[][] = []

  reviews.forEach((review, i) => {
    const pos = Math.floor(i / 5)
    if (groups[pos] === undefined) groups[pos] = []
    groups[pos].push(review)
  })

  const classes = [
    "col-span-6 lg:col-span-2",
    "col-span-6 md:col-span-3 lg:col-span-2",
    "col-span-6 md:col-span-3 lg:col-span-2",
    "col-span-6 md:col-span-3",
    "col-span-6 md:col-span-3",
  ]

  const revClasses = [...classes].reverse()

  return (
    <>
      <div className="hidden md:flex gap-2">
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide left"
          onClick={() => emblaApi?.scrollPrev()}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide right"
          onClick={() => emblaApi?.scrollNext()}
        >
          <ChevronRight />
        </Button>
      </div>
      <div
        className="my-2 border border-carbon-500 rounded-sm overflow-hidden h-full"
        ref={emblaRef}
      >
        <div className="flex touch-pan-y ml-[calc(1rem_*_-1)] h-full">
          {groups.map((reviewGroup, i) => {
            return (
              <div
                className="flex-[0_0_100%] min-w-0 relative pl-2"
                key={"reviewGroup" + i}
              >
                <div className="grid grid-cols-6 justify-between gap-[1px] h-full">
                  {reviewGroup.map((review, reviewI) => {
                    return (
                      <ReviewCard
                        className={cn(
                          "col-span-2",
                          i % 2 === 0 ? classes[reviewI] : revClasses[reviewI]
                        )}
                        review={review}
                        key={review.id + "-" + reviewI}
                      />
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="md:hidden flex gap-2">
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide left"
          onClick={() => emblaApi?.scrollPrev()}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant={"default"}
          size={"icon"}
          aria-label="Slide right"
          onClick={() => emblaApi?.scrollNext()}
        >
          <ChevronRight />
        </Button>
      </div>
    </>
  )
}
